<template>
  <div class="app-container site-single">
    <div class="page-title">
      <h2>Surveillance</h2>
    </div>
    <el-card class="box-card">
      <div class="filter-container">
        <el-button-group>
          <el-button :type="listType=='timeline'?'primary':'secondary'" icon="el-icon-s-grid" @click="listType='timeline'" />
          <el-button :type="listType=='list'?'primary':'secondary'" icon="el-icon-s-order" @click="listType='list'" />
        </el-button-group>
        <el-input
          v-model="query.keyword"
          clearable
          :placeholder="$t('general.keyword')"
          class="wd-200 mr-15 filter-item"
          @keyup.enter.native="handleFilterSitePages"
        />
        <el-button
          v-waves
          class="mr-15 filter-item"
          type="primary"
          :icon="query.keyword!=''?'el-icon-search':'el-icon-refresh'"
          @click="handleFilterSitePages"
        >
          <span v-show="query.keyword!=''">{{ $t("general.search") }}</span>
          <span v-show="query.keyword==''">{{ $t("general.refresh") }}</span>
        </el-button>
      </div>
      <el-table
        v-show="listType=='list'"
        v-loading="loading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column align="center" :label="$t('site.url')" min-width="300px">
          <template slot-scope="scope">
            <div class="full-width">
              <div class="half-photo">
                <div class="demo-basic--circle">
                  <div>
                    <el-image
                      style="width: 100px; height: 100px"
                      :src="scope.row.capture_gallery[0]"
                      fit="cover"
                      :preview-src-list="[scope.row.capture_gallery]"
                    />
                  </div>
                </div>
              </div>
              <div class="half-right">
                <div class="in-td">
                  <h4>{{ scope.row.url }}</h4>
                  <small>{{ scope.row.title }}</small>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          :label="$t('site.VersionNumber')"
          width="100"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.version_number }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          :label="$t('site.createdAt')"
          width="200"
        >
          <template slot-scope="scope">
            <span>{{ createdAtFormat(scope.row.created_at) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div v-show="listType=='timeline'" class="timeline-block">
        <div v-if="isEmptyArray(list)" class="no-selection">
          <el-empty :description="$t('site.noPage')" />
        </div>
        <el-timeline>
          <el-timeline-item
            v-for="(page, index) in list"
            :key="index"
            :timestamp="page.title"
            placement="top"
          >
            <el-card :class="page.score_status+'-version-bg'">
              <el-row :gutter="24">
                <el-col :span="8">
                  <el-image
                    style="width: 100%; height: auto; max-height:200px"
                    :src="page.capture_gallery[0]"
                    fit="cover"
                    :preview-src-list="[page.capture_gallery]"
                  />
                </el-col>
                <!-- <el-col :span="2">
                        <status-indicator active|positive|intermediary|negative|pulse></status-indicator>
                        <status-indicator status="positive" pulse />
                    </el-col> -->
                <el-col :span="12" class="page-details">
                  <a :href="page.url" class="link" target="_blank"><h4>{{ page.url }}</h4></a>
                  <p>{{ $t('site.VersionNumber') }} : {{ page.version_number }} <status-indicator :status="page.score_status" pulse /></p>
                  <p>{{ $t('site.createdAt') }} : {{ createdAtFormat(page.created_at) }}</p>
                </el-col>
              </el-row>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>

      <pagination
        v-if="total > 0"
        :total="total"
        :page.sync="query.page"
        :limit.sync="query.limit"
        @pagination="getSitePagesList"
      />

    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
const pageResource = new Resource('site_page_versions');
export default {
  name: 'SitePagesList',
  components: { Pagination },
  directives: { waves, permission, role },
  data() {
    return {
      listType: 'timeline',
      list: null,
      total: 0,
      loading: false,
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
      },
      site: {
        url: '',
        capture: '',
        title: '',
      },
    };
  },
  computed: {},
  created() {
    this.getSitePagesList();
  },
  methods: {
    checkPermission,
    checkRole,
    createdAtFormat(created_at){
      return moment(created_at).locale('fr').calendar();
    },
    isEmptyArray(array) {
      if (typeof array !== 'undefined' && array !== null && array.length === 0) {
        return true;
      }
    },
    async getSitePagesList() {
      this.loading = true;
      const { limit, page } = this.query;
      const { data, meta } = await pageResource.list(this.query);
      this.list = data;
      this.list.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.total = meta.total;
      this.loading = false;
    },
    handleFilterSitePages() {
      this.query.page = 1;
      this.getSitePagesList();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-card__body {
  padding: 10px !important;
}

.half-right {
  width: 100% !important;
  float: none !important;
  text-align: left !important;
  padding: 10px;
}
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
